<template>
    <div class="blog-post-author">
        <img class="blog-post-author-image" :src="authorImage" :alt="author.name" />

        <div class="blog-post-author-info">
            <div class="blog-post-author-name">{{ author.name }}</div>
            <div class="blog-post-author-role">{{ author.role }}</div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AuthorProfile',

    props: {
        author: {
            required: true,
            type: Object,
        }
    },

    data: function () {
        return {}
    },

    created() {

    },

    components: {},

    computed: {
        authorImage() {
            return require(`@/assets/blog/authors/${this.author.image}`)
        },
    },

    methods: {}
}


</script>
