<template>
    <div class="blog-post-meta">
        <div class="blog-author-date">

            <AuthorProfile v-for="author in meta.authors" :author="author" :key="author.name" />

            <div class="visuals-by" v-if="meta.visualsBy">
                <AuthorProfile :author="meta.visualsBy"  />
            </div>

            <div class="blog-post-date">
                <div class="label">Published</div>
                <div class="date">{{ meta.publishedDate }}</div>
            </div>
        </div>
        <div class="blog-post-share">
            <img @click="shareToLinkedin" src="../../assets/blog/social/linkedin.svg" alt="Linkedin">
            <img @click="shareToFacebook" src="../../assets/blog/social/facebook.svg" alt="Facebook">
            <img @click="shareToWhatsApp" src="../../assets/blog/social/whatsapp.svg" alt="WhatsApp">
        </div>
    </div>
</template>

<script>
import AuthorProfile from '@/components/blog/AuthorProfile.vue'
export default {
    name: 'AuthorMetaShare',

    props: {
        meta: {
            required: true,
            type: Object
        }
    },

    data: function () {
        return {}
    },

    created() {

    },

    components: {AuthorProfile},

    computed: {
        currentRoute() {
            return `https://superology.com${this.$route.fullPath}`
        }
    },

    methods: {
        shareToFacebook() {
            const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(this.currentRoute)}`
            this.socialShare(facebookShareUrl)
        },

        shareToLinkedin() {
            const linkedinShareUrl = `https://www.linkedin.com/shareArticle?url=${encodeURIComponent(this.currentRoute)}`
            this.socialShare(linkedinShareUrl)
        },

        shareToWhatsApp() {
            const whatsapShareUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(this.currentRoute)}`
            this.socialShare(whatsapShareUrl)
        },

        socialShare(url) {
            window.open(url, '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=300,width=320')
        }
    }
}


</script>
