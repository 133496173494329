<template>
    <div class="so-blog blog-post animated fadeIn">
        <div class="hero-header">
            <div class="container">
                <div class="blog-tags">
                    <div class="blog-tag" v-for="tag in tags" :key="tag">{{ tag }}</div>
                </div>

                <h1>
                    <slot name="title"></slot>
                </h1>

                <div class="blog-post-intro">
                    <slot name="excerpt"></slot>
                </div>
            </div>
        </div>

        <div class="blog-post-header-stripe stripe-mask dark"></div>

        <div class="blog-post-content">
            <div class="container">
                <div class="so-article">
                    <AuthorMetaShare :meta="meta" />

                    <div class="blog-post-article">
                        <slot name="content"></slot>
                    </div>
                </div>

                <LatestPosts />
            </div>
        </div>

        <Hiring />
    </div>
</template>

<script>
import LatestPosts from '@/components/blog/LatestPosts.vue'
import Hiring from '@/components/home/Hiring.vue'
import GsapReveal from '@/mixins/GsapReveal'
import AuthorMetaShare from '@/components/blog/AuthorMetaShare.vue'

export default {
    name: 'BlogPostLayout',

    props: {
        tags: {
            required: true,
            type: Array
        },

        meta: {
            required: true,
            type: Object
        }
    },

    data: function () {
        return {}
    },

    mixins: [GsapReveal],

    created() {

    },

    components: {LatestPosts, Hiring, AuthorMetaShare},

    computed: {},

    methods: {}
}


</script>
