<template>
    <div class="latest-posts">
        <h3>Latest Posts</h3>

        <div class="latest-post-previews">
            <ArchiveBlogPostItem v-for="post in latestPostsWithoutCurrent" :key="post.title" :post="post"/>
        </div>
    </div>
</template>

<script>
import ArchiveBlogPostItem from '@/components/blog/ArchiveBlogPostItem.vue'
import posts from '@/views/blog/posts/posts'

export default {
    name: 'LatestPosts',

    props: {},

    data: function () {
        return {
            posts
        }
    },

    created() {

    },

    components: {ArchiveBlogPostItem},

    computed: {
        latestPostsWithoutCurrent(){
            return posts.filter(post => post.url !== this.$route.path).slice(0, 3)
        }
    },

    methods: {}
}


</script>
