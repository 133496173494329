export default {
    vatroslavMilos: {
        name: 'Vatroslav Miloš',
        role: 'Employer Branding Specialist',
        image: 'vatroslav-milos.jpg',
    },
    petarKujundzic: {
        name: 'Petar Kujundžić',
        role: 'Platform Architect',
        image: 'petar-kujundzic.jpg',
    },
    marijanRalasic: {
        name: 'Marijan Ralašić',
        role: 'Data Architect',
        image: 'marijan-ralasic.jpg',
    },
    zvonimiraMilevcic: {
        name: 'Zvonimira Milevčić',
        role: 'Senior Employer Branding Manager',
        image: 'zvonimira-milevcic.jpg',
    },
    tenaPezdevsek: {
        name: 'Tena Pezdevšek',
        role: 'Brand Designer',
        image: 'tena-pezdevsek.jpg',
    },
    adisMustedanagic: {
        name: 'Adis Mustedanagić',
        role: 'Engineering Manager',
        image: 'adis-mustedanagic.jpg'
    }
}
