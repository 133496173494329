<template>
    <div class="video-wrapper">
        <iframe
            :src="videoUrl"
            :title="title"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
        ></iframe>
    </div>
</template>

<script>
export default {
    name: 'YoutubeVideo',

    props: {
        videoId: {
            required: true,
            type: String
        },

        title: {
            required: true,
            type: String
        }
    },

    data: function () {
        return {}
    },

    created() {

    },

    components: {},

    computed: {
        videoUrl(){
            return `https://www.youtube.com/embed/${this.videoId}`
        }
    },

    methods: {}
}


</script>
