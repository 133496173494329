<template>
    <BlogPostLayout :tags="tags" :meta="meta">
        <template v-slot:title>You're not the boss of me: empowered teams and where to find them</template>

        <template v-slot:excerpt>It takes an empowered team to deliver a great product. And this is just how we like
            them at Superology.
        </template>

        <template v-slot:content>
            <img class="blog-post-image" src="../../../assets/blog/images/tennis-room-meeting.jpg"
                 alt="Superology meeting">

            <p>I named this text “You’re not the boss of me” after a talk I held at last year’s SuperMinds conference,
                partially because I really like the band They Might be Giants and partially because of its clickbait
                quality. It serves as a good intro to what I wanted to talk about – teams with no stakeholders.</p>

            <p>To give a bit of background, I worked in an agency for just a smidge over a decade. First as an iOS
                developer, then as a team lead and in a few other roles, I eventually moved into product space as an
                engineering manager for the team that develops the SuperSocial platform at Superology.</p>

            <p>Once I got over the initial culture shock and recovered from the agency mindset, there were a few things
                I wanted to know better before moving to a product team.</p>

            <h2>What’s a vertical?</h2>

            <p>If you’re in almost every product company longer than 30 minutes, you’re bound to hear someone mention a
                vertical or a similar name that basically describes a product team within a larger organisation.</p>

            <p>The background of these teams comes from an organisational issue every large project eventually must cope
                with – how to split work when you start scaling your team up. Development often requires feature teams
                to emerge until they deliver that feature and move on to the next one. Still, in a product company, more
                commonly, you will form teams around a smaller part of the app that will continue working for much
                longer, even once the feature is delivered. And I can’t stress this enough, <i>delivered does not mean
                    finished by any means</i>.</p>

            <p>A vertical team is named that way because they are cross-functional and will have a full stack of
                developers, designers, and product managers rather than a feature team which might be constrained to a
                specific tech (e.g. iOS team).</p>

            <img class="blog-post-image" src="../../../assets/blog/images/adis-blog-verticals.jpg" alt="Verticals">

            <h2>Stakeholder who?</h2>

            <p>A somewhat uncommon model of working, especially for my own team, is the fact that there is no single
                stakeholder who decides what will be the next feature. Instead, based on what we think might perform
                well, the team decides to create an experiment, measure relevant metrics and take input from our actual
                stakeholders – our users. We often say that data is our stakeholder, or users are our stakeholders, and
                our success is measured only by how well the users receive what we deliver.</p>

            <p>Compare this to a directional model with a well-known stakeholder, a person who has requirements, a
                project manager that refines them, and possibly a project lead that might estimate and create a
                specification, which is then passed on to developers. Such a team often does not have the power to
                decide the direction of what needs to be done, unlike an empowered team described above.</p>

            <YoutubeVideo
                videoId="mcxnGEhoZq8"
                title="You're not the boss of me | SuperMinds 2022"
            />

            <h2>How do you decide what to do?</h2>

            <p>The answer to this question is rather simple – and we call it discovery. This process envelops a lot of
                smaller processes which will eventually give us a go for developing and delivering a feature:</p>

            <ul>
                <li>Discovery and ideation meetings: where we discuss ideas for further development</li>
                <li>User interviews: where we try to find pain points, missed opportunities and places or improvement
                </li>
                <li>A/B tests: where we sometimes validate feature ideas on the smallest prototype possible and
                    perioically even test feature refinements
                </li>
                <li>Market research: where we try to figure out what the competition does, how to gain an edge over them
                    etc.
                </li>
            </ul>

            <p>All these things converge together to form one continuous process that – since we might revisit an
                existing feature during its lifetime – does not have a very clear start or an obvious end, and that’s
                what we call discovery.</p>

            <h2>Key takeaways</h2>

            <p>To sum up the process of having no boss, we can refer to the cycle below:</p>

            <img class="blog-post-image" src="../../../assets/blog/images/adis-blog-cycle.jpg" alt="Cycle">

            <ul class="numbered">
                <li>Start with an idea for a feature.</li>
                <li>Define the smallest amount of work we can use to validate that feature, usually in the form of an
                    A/B test
                </li>
                <li>Either implement the feature or discard it.</li>
                <li>Continue learning from the above lesson to generate new and better ideas and return to step 1.</li>
            </ul>

            <h2>Further exploration</h2>

            <p>Like many companies, we’re inspired by success stories in the industry and if you want to learn more,
                here are a couple of books that’ll show you the way.</p>

            <img class="blog-post-image" src="../../../assets/blog/images/adis-blog-books.jpg" alt="Further exploration - books">

        </template>
    </BlogPostLayout>
</template>

<script>
import BlogPostLayout from '@/components/blog/BlogPostLayout.vue'
import blogMetadata from '@/util/blogMetadata'
import authors from '@/views/blog/posts/authors'
import YoutubeVideo from '@/components/blog/YoutubeVideo.vue'

export default {
    name: 'YouAreNotTheBossOfMe',

    metaInfo: blogMetadata.youAreNotTheBossOfMe,

    props: {},

    data: function () {
        return {
            tags: ['Engineering', 'Organisation', 'Empowered work', 'SuperSocial'],
            meta: {
                authors: [authors.adisMustedanagic],
                publishedDate: '13 feb 2023'
            }
        }
    },

    created() {

    },

    components: {YoutubeVideo, BlogPostLayout},

    computed: {},

    methods: {}
}
</script>
