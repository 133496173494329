import { OG_TYPE, OG_URL, OG_TITLE, OG_DESCRIPTION, REL_CANONICAL, DESCRIPTION, TWITTER_CARD, TWITTER_IMAGE, OG_IMAGE } from './metaPropConstants.js'

const defaultMeta = {
    ogType: {
        property: OG_TYPE,
        content: 'article'
    },
    twitterCard: {
        name: TWITTER_CARD,
        content: 'summary_large_image'
    },
    ogImage: {
        property: OG_IMAGE,
        content: 'https://superology.com/static/ogimg.jpg'
    },
    twitterImage: {
        name: TWITTER_IMAGE,
        content: 'https://superology.com/static/ogimg.jpg'
    },
}

const blogImgUrl = {
    howMoreDowntimeDrivesCreativity: getBlogImageUrl('superology-office-chairs.jpg'),
    fastlyPushingContentCLoserToUsers: getBlogImageUrl('fastly-stickers.jpg'),
    beamMeUpScottySasaJuric: getBlogImageUrl('sasa-juric.jpg'),
    advanceUserExperienceWithClickHouse: getBlogImageUrl('superology-office-halls.jpg'),
    youAreNotTheBossOfMe: getBlogImageUrl('tennis-room-meeting.jpg')
}

const ogImageProps = {
    howMoreDowntimeDrivesCreativity: {
        ogImage: {
            property: OG_IMAGE,
            content: blogImgUrl.howMoreDowntimeDrivesCreativity
        },
        twitterImage: {
            name: TWITTER_IMAGE,
            content: blogImgUrl.howMoreDowntimeDrivesCreativity
        },
    },
    fastlyPushingContentCLoserToUsers: {
        ogImage: {
            property: OG_IMAGE,
            content: blogImgUrl.fastlyPushingContentCLoserToUsers
        },
        twitterImage: {
            name: TWITTER_IMAGE,
            content: blogImgUrl.fastlyPushingContentCLoserToUsers
        },
    },
    beamMeUpScottySasaJuric: {
        ogImage: {
            property: OG_IMAGE,
            content: blogImgUrl.beamMeUpScottySasaJuric
        },
        twitterImage: {
            name: TWITTER_IMAGE,
            content: blogImgUrl.beamMeUpScottySasaJuric
        },
    },
    advanceUserExperienceWithClickHouse: {
        ogImage: {
            property: OG_IMAGE,
            content: blogImgUrl.advanceUserExperienceWithClickHouse
        },
        twitterImage: {
            name: TWITTER_IMAGE,
            content: blogImgUrl.advanceUserExperienceWithClickHouse
        },
    },
    youAreNotTheBossOfMe: {
        ogImage: {
            property: OG_IMAGE,
            content: blogImgUrl.youAreNotTheBossOfMe
        },
        twitterImage: {
            name: TWITTER_IMAGE,
            content: blogImgUrl.youAreNotTheBossOfMe
        },
    }
}

function getBlogImageUrl(fileName) {
    return `https://superology.com/static/blog-og-img/${fileName}`
}

const description = {
    fastlyPushingContentCLoserToUsers: 'Fastly is accelerating Superology’s caching and content delivery capabilities for sports betting apps on par with the most popular social media apps.',
    beamMeUpScottySasaJuric: 'Saša Juric is an Elixir pioneer and engineering mentor. At Superology, he’s helping the Backend team with BEAM, Protobuf and Kafka development.',
    advanceUserExperienceWithClickHouse: 'Superology uses quantitative data to modify customer experience and increase the functionality of our sports entertainment app with ClickHouse.',
    howMoreDowntimeDrivesCreativity: 'People are the building block of Superology. That’s why we have an unlimited vacation policy.',
    youAreNotTheBossOfMe: 'It takes an empowered team to deliver great tech. The ultimate stakeholder at Superology is its users.'
}

export default {

    howMoreDowntimeDrivesCreativity: {
        title: 'How more downtime drives creativity and innovation | Superology',
        link: [
            {rel: REL_CANONICAL, href: 'https://superology.com/blog/how-more-downtime-drives-creativity-and-innovation'}
        ],
        meta: [
            {
                property: OG_URL,
                content: 'https://superology.com/blog/how-more-downtime-drives-creativity-and-innovation'
            },
            defaultMeta.ogType,
            {
                property: OG_TITLE,
                content: 'How more downtime drives creativity and innovation | Superology'
            },
            {
                property: OG_DESCRIPTION,
                content: description.howMoreDowntimeDrivesCreativity
            },
            {
                name: DESCRIPTION,
                content: description.howMoreDowntimeDrivesCreativity
            },
            defaultMeta.twitterCard,
            ogImageProps.howMoreDowntimeDrivesCreativity.ogImage,
            ogImageProps.howMoreDowntimeDrivesCreativity.twitterImage,
        ],
    },

    fastlyPushingContentCLoserToUsers: {
        title: 'Fastly: Pushing content closer to users | Superology',
        link: [
            {rel: REL_CANONICAL, href: 'https://superology.com/blog/fastly-pushing-content-closer-to-users'}
        ],
        meta: [
            {
                property: OG_URL,
                content: 'https://superology.com/blog/fastly-pushing-content-closer-to-users'
            },
            defaultMeta.ogType,
            {
                property: OG_TITLE,
                content: 'Fastly: Pushing content closer to users | Superology'
            },
            {
                property: OG_DESCRIPTION,
                content: description.fastlyPushingContentCLoserToUsers
            },
            {
                name: DESCRIPTION,
                content: description.fastlyPushingContentCLoserToUsers
            },
            defaultMeta.twitterCard,
            ogImageProps.fastlyPushingContentCLoserToUsers.ogImage,
            ogImageProps.fastlyPushingContentCLoserToUsers.twitterImage,
        ],
    },

    beamMeUpScottySasaJuric: {
        title: 'BEAM me up, Scotty: A short talk with Saša Jurić | Superology',
        link: [
            {
                rel: REL_CANONICAL,
                href: 'https://superology.com/blog/BEAM-me-up-scotty-a-short-talk-with-sasa-juric'
            }
        ],
        meta: [
            {
                property: OG_URL,
                content: 'https://superology.com/blog/BEAM-me-up-scotty-a-short-talk-with-sasa-juric'
            },
            defaultMeta.ogType,
            {
                property: OG_TITLE,
                content: 'BEAM me up, Scotty: A short talk with Saša Jurić | Superology'
            },
            {
                property: OG_DESCRIPTION,
                content: description.beamMeUpScottySasaJuric
            },
            {
                name: DESCRIPTION,
                content: description.beamMeUpScottySasaJuric
            },
            defaultMeta.twitterCard,
            ogImageProps.beamMeUpScottySasaJuric.ogImage,
            ogImageProps.beamMeUpScottySasaJuric.twitterImage,
        ],
    },

    advanceUserExperienceWithClickHouse: {
        title: 'How we use data to advance user experience with ClickHouse | Superology',
        link: [
            {
                rel: REL_CANONICAL,
                href: 'https://superology.com/blog/how-we-use-data-to-advance-user-experience-with-clickhouse'
            }
        ],
        meta: [
            {
                property: OG_URL,
                content: 'https://superology.com/blog/how-we-use-data-to-advance-user-experience-with-clickhouse'
            },
            defaultMeta.ogType,
            {
                property: OG_TITLE,
                content: 'How we use data to advance user experience with ClickHouse | Superology'
            },
            {
                property: OG_DESCRIPTION,
                content: description.advanceUserExperienceWithClickHouse
            },
            {
                name: DESCRIPTION,
                content: description.advanceUserExperienceWithClickHouse
            },
            defaultMeta.twitterCard,
            ogImageProps.advanceUserExperienceWithClickHouse.ogImage,
            ogImageProps.advanceUserExperienceWithClickHouse.twitterImage,
        ],
    },

    youAreNotTheBossOfMe: {
        title: 'You\'re not the boss of me: empowered teams and where to find them | Superology',
        link: [
            {
                rel: REL_CANONICAL,
                href: 'https://superology.com/blog/you-are-not-the-boss-of-me-empowered-teams-and-where-to-find-them'
            }
        ],
        meta: [
            {
                property: OG_URL,
                content: 'https://superology.com/blog/you-are-not-the-boss-of-me-empowered-teams-and-where-to-find-them'
            },
            defaultMeta.ogType,
            {
                property: OG_TITLE,
                content:  'You\'re not the boss of me: empowered teams and where to find them | Superology',
            },
            {
                property: OG_DESCRIPTION,
                content: description.youAreNotTheBossOfMe
            },
            {
                name: DESCRIPTION,
                content: description.youAreNotTheBossOfMe
            },
            defaultMeta.twitterCard,
            ogImageProps.youAreNotTheBossOfMe.ogImage,
            ogImageProps.youAreNotTheBossOfMe.twitterImage,
        ],
    }
}
